$copy-color: #0F62FE;

.copyLink {
  height: 31px;
  border: 2px solid $copy-color;
  border-radius: 15px;
  display: flex;
  align-items: center;
  flex-shrink: 1;
  overflow: hidden;

  .url {
    font-size: 10px;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    flex-shrink: 1;
    min-width: 0;
  }

  .url + div {
    flex-grow: 1;
    height: 100%;
    background-color: $copy-color;
    color: #ffffff;
    border-radius: 15px;
    padding-left: 1rem;
    padding-right: 1rem;
    cursor: default;

    margin-top: -2px;
    margin-bottom: -2px;
    margin-right: -2px;
    align-self: center;
    display: flex;
    align-items: center;
  }

  .url + div:hover {
    opacity: 0.75;
  }
}
