.base {
  display: inline-block;

  .btn {
    padding-right: 12px;
    width: 100%;
    width: -moz-available;
    width: -webkit-fill-available;
    width: stretch;
    text-align: center;
  }

  .originalPrice {
    margin-top: 8px;
    font-size: 12px;
    text-align: center;
    color: #c6c6c6;
  }
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 2;
  cursor: pointer;
}
