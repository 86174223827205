.reverse {
  flex-direction: row-reverse;
}

.avatarContainer {
  padding-top: 0.5rem;
  text-align: center;
}

.changeAnchor {
  line-height: 40px;
  margin-top: 0.5rem;
}
.changeContainer {}

@media (max-width: 42rem) {
  .changeContainer {
    position: relative;
  }
  .changeAnchor {
    width: unset;
    position: absolute;
    right: 0;
    display: inline-block;
    bottom: 0;
  }
}

.shrinking {}

@media (max-width:52rem) and (min-width:42rem) {
  .shrinking {
    font-size: 16px;
  }
}
