.earnings {
  padding: 0 1rem;
}

.info {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #db2663;
  margin-bottom: 1rem;

  svg {
    stroke: #db2663;
    fill: #db2663;
    margin-right: 0.5rem;
  }
}
