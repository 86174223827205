@import '@carbon/themes/scss/themes';

$carbon--theme: $carbon--theme--g100;
@include carbon--theme();

.content {
  position: relative;
  overflow: hidden;
}
.container {
  display: flex;
  height: calc(100% - 40px);
  overflow: hidden;
  padding: 9px 0 0 16px;

  .coverImage {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 280px;
    height: 351px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .listeningBlock {
    height: calc(100vh - 40px);
    display: flex;
    flex-direction: column;
    padding-left: 16px;
    padding-right: 16px;
    flex-grow: 1;
    overflow: hidden;
  }

  .bookName {
    font-size: 20px;
    line-height: 26px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: 36px;
  }

  .author {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.32px;
    margin-top: 10px;

    svg {
      fill: #f4f4f4;
    }
  }

  .line {
    display: none;
  }

  .tags {
    margin-top: 6px;

    span {
      min-height: 1.5rem;
      height: auto;
      background-color: $ui-02;
      color: $text-01
    }

    & > span:first-child {
      margin-left: 0;
    }
  }

  .playlist {
    margin-top: 10px;
    margin-bottom: 7px;
  }

  .playlist.hidden {}
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
    align-items: center;
    padding: 18px 0 0 0;
    max-width: calc(100vw - 96px);
    margin: auto;

    .coverImage {
      width: 100%;
      height: auto;
      flex-grow: 1;
      flex-shrink: 1;
      visibility: hidden;
    }

    .listeningBlock {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
      height: auto;
      flex-grow: 0;
      padding-bottom: 34px;
    }

    .bookName {
      padding-top: 10px;
    }

    .line {
      display: block;
      width: 100%;
      border-top: 1px solid #565656;
      margin-top: 10px;
    }

    .tags {
      display: none;
    }

    .playlist {
      position: absolute;
      top: 40px;
      width: 100%;
      height: calc(100vh - 40px);
      padding: 5px 0 0 5px;
      margin-top: 0;
      left: 0;
      background-color: #161616;
    }

    .playlist.hidden {
      transform: translateX(-100%);
    }
  }
}
